body {
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

pre {
    white-space: pre-wrap;
    text-align: left;
    font-size: 14px;
    font-family: "IBM Plex Sans", sans-serif;
}

h4 {
    font-size: 20px;
    font-weight: 500;
    color: #272f52;

    @media (max-width: 768px) {
        font-size: 16px;
    }

    @media (max-width: 576px) {
        font-size: 14px;
    }
}
