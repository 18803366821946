
.displaySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f2f2f2;
}

table {
    border-collapse: collapse;
    width: 80%;
    margin: 20px auto;
    font-size: 14px;
    font-family: Arial, sans-serif;
}

/*thead {*/
/*    background-color: #333;*/
/*    color: #fff;*/
/*    text-align: left;*/
/*}*/

/*thead th {*/
/*    padding: 10px;*/
/*}*/

/*tbody tr:nth-child(even) {*/
/*    background-color: #f2f2f2;*/
/*}*/

/*tbody tr:hover {*/
/*    !*background-color: #ddd;*!*/
/*}*/

/*tbody td {*/
/*    padding: 10px;*/
/*    border-bottom: 1px solid #ddd;*/
/*}*/

/*tbody td:first-child {*/
/*    font-weight: bold;*/
/*}*/

/*video {*/
/*    height: 100px;*/
/*    width: 200px;*/
/*}*/


.list {
    min-height: 100vh;
}


.loader {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
    animation: animate 1.2s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.loader span:nth-child(1) {
    filter: blur(5px);
}

.loader span:nth-child(2) {
    filter: blur(10px);
}

.loader span:nth-child(3) {
    filter: blur(25px);
}

.loader span:nth-child(4) {
    filter: blur(50px);
}

.loader:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #f1f1f1;
    border: solid white 10px;
    border-radius: 50%;
}


.apple-toast {
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.apple-toast span {
    font-size: 16px;
    font-weight: 500;
}

.apple-toast button {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}
